// Copyright © 2022 Move Closer

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const DEFAULT_GRID_CONFIG = {
  columnGap: 0,
  columns: 6,
  rowGap: 0,
  rows: 4
}
