















import { BootstrapButton } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'
import { findFirstFocusableElement } from '../../../../../../../../support/dom-utilities'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<GoToTopBtn>({
  name: 'GoToTopBtn',
  components: { BootstrapButton }
})
export class GoToTopBtn extends Vue {
  /**
   * Handles the `@click` event on the main `<button>` element.
   */
  public onBtnClick (): void {
    this.scrollToTop()
  }

  /**
   * Scrolls the viewport to the top (beginning) of the page.
   */
  private scrollToTop (): void {
    if (typeof window === 'undefined') {
      return
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })

    const header: HTMLElement | null = document.getElementById('header')

    if (!header) {
      return
    }

    const firstFocusable: Element | undefined = findFirstFocusableElement(header)

    if (typeof firstFocusable === 'undefined') {
      return
    }

    (firstFocusable as HTMLElement).focus()
  }
}

export default GoToTopBtn
