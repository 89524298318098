






import { Component } from 'vue-property-decorator'

import { isLink, log } from '../../../../support'
import { isRelated } from '../../../../services'

import { AbstractModuleUi } from '../../_abstract'

import {
  FooterModule,
  FooterModuleContentMiddle,
  ResolvedFooterModuleContent
} from '../Footer.contracts'

/**
 * Container component for the `FooterModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
@Component<FooterModuleUi>({
  name: 'FooterModuleUi',
  components: {
    FooterModuleUiPresentation: () => import(
      /* webpackChunkName: "frame" */
      './Footer.ui.presentation.vue'
    )
  }
})
export class FooterModuleUi extends AbstractModuleUi<FooterModule> {
  /**
   * Module's content with all related data already resolved.
   */
  public resolvedData: ResolvedFooterModuleContent | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    this.setInitialResolvedData()

    await Promise.allSettled([
      this.fetchBottom(),
      this.fetchMiddle(),
      this.fetchTop()
    ])
  }

  /**
   * Fetches the related data for the **bottom** section of the module.
   *
   * @see FooterModuleContent.bottom
   * @see ResolvedFooterModuleContent.bottom
   * @see resolvedData.bottom
   */
  private async fetchBottom (): Promise<void> {
    await Promise.allSettled([
      this.fetchBottomNavigation(),
      this.fetchBrandImage(),
      this.fetchBrandLink()
    ])
  }

  /**
   * Fetches the related data for the **middle** section of the module.
   *
   * @see FooterModuleContent.middle
   * @see ResolvedFooterModuleContent.middle
   * @see resolvedData.middle
   */
  private async fetchMiddle (): Promise<void> {
    await Promise.allSettled([
      this.fetchContactSection(),
      this.fetchMiddleNavs()
    ])
  }

  /**
   * Fetches the related data for the **top** section of the module.
   *
   * @see FooterModuleContent.top
   * @see ResolvedFooterModuleContent.top
   * @see resolvedData.top
   */
  private async fetchTop (): Promise<void> {
    await this.fetchTopNav()
  }

  /**
   * Fetches the related data for the navigation inside the bottom section.
   *
   * @see FooterModuleContent.bottom.nav
   * @see ResolvedFooterModuleContent.bottom.nav
   * @see resolvedData.bottom.nav
   */
  private async fetchBottomNavigation (): Promise<void> {
    if (typeof this.resolvedData?.bottom === 'undefined') {
      const message: string = 'FooterModuleUi.fetchBootomNavigation(): Expected [this.resolvedData.bottom] to be defined!'
      log(message, 'error')
      return
    }

    const unresolvedNavigation = this.data.content.bottom?.nav

    if (!isRelated(unresolvedNavigation)) {
      return
    }

    try {
      this.resolvedData.bottom.nav = await this.fetchNavigation(unresolvedNavigation)
    } catch (error) {
      const message: string =
        'FooterModuleUi.fetchBootomNavigation(): Failed to fetch the related navigation from the [RelatedService]!'
      log(message, 'error')
    }
  }

  /**
   * Fetches the related data for the brand link inside the bottom section.
   *
   * @see FooterModuleContent.bottom.brandLink
   * @see ResolvedFooterModuleContent.bottom.brandLink
   * @see resolvedData.bottom.brandLink
   */
  private async fetchBrandLink (): Promise<void> {
    if (typeof this.resolvedData?.bottom === 'undefined') {
      const message: string = 'FooterModuleUi.fetchBrandLink(): Expected [this.resolvedData.bottom] to be defined!'
      log(message, 'error')
      return
    }

    const unresolvedLink = this.data.content.bottom?.brandLink

    if (!isLink(unresolvedLink) && !isRelated(unresolvedLink)) {
      return
    }

    if (isLink(unresolvedLink)) {
      this.resolvedData.bottom.brandLink = unresolvedLink
      return
    }

    try {
      this.resolvedData.bottom.brandLink = await this.fetchLink(unresolvedLink)
    } catch (error) {
      const message: string =
        'FooterModuleUi.fetchBrandLink(): Failed to fetch the related link from the [RelatedService]!'
      log(message, 'error')
    }
  }

  /**
   * Fetches the related data for the brand image inside the bottom section.
   *
   * @see FooterModuleContent.bottom.brandImage
   * @see ResolvedFooterModuleContent.bottom.brandImage
   * @see resolvedData.bottom.brandImage
   */
  private async fetchBrandImage (): Promise<void> {
    if (typeof this.resolvedData?.bottom === 'undefined') {
      const message: string = 'FooterModuleUi.fetchBrandImage(): Expected [this.resolvedData.bottom] to be defined!'
      log(message, 'error')
      return
    }

    const unresolvedImage = this.data.content.bottom?.brandImage

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      this.resolvedData.bottom.brandImage = await this.fetchImage(unresolvedImage)
    } catch (error) {
      const message: string =
        'FooterModuleUi.fetchBrandImage(): Failed to fetch the related image from the [RelatedService]!'
      log(message, 'error')
    }
  }

  /**
   * Fetches the related data for the navigations inside the middle section.
   *
   * @see FooterModuleContent.middle.bottomNav
   * @see FooterModuleContent.middle.mainNav
   * @see FooterModuleContent.middle.socialNav
   * @see ResolvedFooterModuleContent.middle.bottomNav
   * @see ResolvedFooterModuleContent.middle.mainNav
   * @see ResolvedFooterModuleContent.middle.socialNav
   * @see resolvedData.middle.bottomNav
   * @see resolvedData.middle.mainNav
   * @see resolvedData.middle.socialNav
   */
  private async fetchMiddleNavs (): Promise<void> {
    if (typeof this.resolvedData?.middle === 'undefined') {
      const message: string = 'FooterModuleUi.fetchMiddleNavs(): Expected [this.resolvedData.middle] to be defined!'
      log(message, 'error')
      return
    }

    const navigations: Array<keyof FooterModuleContentMiddle> = [
      'bottomNav',
      'mainNav',
      'socialNav'
    ]

    await Promise.allSettled(
      navigations.map(navigation => new Promise<void>((resolve, reject) => {
        const navigationToResolve = this.data.content.middle?.[navigation]

        if (!isRelated(navigationToResolve)) {
          return reject(new Error(`[${navigation}] navigation is NOT a [Related] object!`))
        }

        this.fetchNavigation(navigationToResolve)
          .then(response => {
            // We can safely suppress the `no-non-null-assertion` rule, as all the required checks
            // have been made earlier, and we are 100% sure that
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.resolvedData!.middle![navigation] = response
            return resolve()
          })
          .catch(error => {
            const message: string = `FooterModuleUi.fetchMiddleNavs(): Failed to fetch the related data for the [${navigation}] navigation from the [RelatedService]!`
            log([message, error], 'error')
            return reject(new Error(message))
          })
      }))
    )
  }

  /**
   * Fetches the related data for the contact section inside the middle section.
   *
   * @see FooterModuleContent.middle.contactSection
   * @see ResolvedFooterModuleContent.middle.contactSection
   * @see resolvedData.middle.contactSection
   */
  private async fetchContactSection (): Promise<void> {
    if (typeof this.resolvedData?.middle?.contactSection === 'undefined') {
      const message: string = 'FooterModuleUi.fetchContactSection(): Expected [this.resolvedData.middle.contactSection] to be defined!'
      log(message, 'error')
      return
    }

    const iconNavToResolve = this.data.content.middle?.contactSection?.iconNav

    if (!isRelated(iconNavToResolve)) {
      return
    }

    try {
      this.resolvedData.middle.contactSection.iconNav = await this.fetchNavigation(iconNavToResolve)
    } catch (error) {
      log('FooterModuleUi.fetchContactSection(): Failed to fetch the related data for the icon navigation inside the contact section from the [RelatedService]!', 'error')
    }
  }

  /**
   * Fetches the related data for the navigation inside the top section.
   *
   * @see FooterModuleContent.top.nav
   * @see ResolvedFooterModuleContent.top.nav
   * @see resolvedData.top.nav
   */
  private async fetchTopNav (): Promise<void> {
    if (typeof this.resolvedData?.top === 'undefined') {
      const message: string = 'FooterModuleUi.fetchTopNav(): Expected [this.resolvedData.top] to be defined!'
      log(message, 'error')
      return
    }

    const topNavToResolve = this.data.content.top?.nav

    if (!isRelated(topNavToResolve)) {
      return
    }

    try {
      this.resolvedData.top.nav = await this.fetchNavigation(topNavToResolve)
    } catch (error) {
      log('FooterModuleUi.fetchTopNav(): Failed to fetch the related top navigation from the [RelatedService]!', 'error')
    }
  }

  /**
   * Sets the initial value for the `resolvedData` property. The "initial value" here means the raw
   * data that came from the API, without any related data resolving being made (this step will be
   * done later, in the dedicated async methods).
   *
   * @see FooterModuleContent
   * @see ResolvedFooterModuleContent
   */
  private setInitialResolvedData (): void {
    // Spread (copy) the module's content. Fields set as `undefined` will be populated in the
    // dedicated async methods that will resolve the fields' data using `RelatedService`.
    this.resolvedData = {
      bottom: {
        ...this.data.content.bottom,
        brandImage: undefined,
        brandLink: undefined,
        nav: undefined
      },
      middle: {
        ...this.data.content.middle,
        bottomNav: undefined,
        contactSection: {
          ...this.data.content.middle?.contactSection,
          iconNav: undefined
        },
        mainNav: [],
        socialNav: undefined
      },
      top: {
        ...this.data.content.top,
        nav: undefined
      }
    }
  }
}

export default FooterModuleUi
