



















import { BootstrapImage, BootstrapLink, Link } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Creator>({
  name: 'Creator',
  components: { BootstrapImage, BootstrapLink }
})
export class Creator extends Vue {
  public get link (): Link {
    return {
      target: 'https://movecloser.co/',
      label: 'Strona internetowa agencji Move Closer'
    }
  }

  public get logo (): unknown {
    return require('../../../../../../../../../assets/logos/movecloser.svg')
  }
}

export default Creator
