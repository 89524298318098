// Copyright © 2022 Move Closer

import { BootstrapBreadcrumbsItem } from '@movecloser/ui-core'

import { ContentParent, isContentParent } from '../../../../contracts'

import { BREADCRUMBS_ROOT_FALLBACK_TARGET, BREADCRUMBS_ROOT_ICON_NAME } from './Hero.ui.config'
import {
  HeroModuleAnimationVersionContent,
  HeroModuleBasicVersionContent,
  HeroModuleSliderVersionContent
} from '../Hero.contracts'
import { isPlainObject } from 'lodash'
import { isRelated } from '../../../../services'

/**
 * Converts the passed-in `contentParent` object to the array of `BootstrapBreadcrumbsItem` objects.
 *
 * @param contentParent - The `ContentParent` object that is to be converted to breadcrumbs.
 * @param [acc=[]] - The array of breadcrumbs extracted (converted) from the previous function calls
 *   (the "acc" name is a shorthand for the "accumulator" (similar to the `Array.reduce()` method)).
 *
 * @recursive
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const contentParentToBreadcrumbs = (
  contentParent: ContentParent | null,
  acc: BootstrapBreadcrumbsItem[] = []
): BootstrapBreadcrumbsItem[] => {
  /**
   * Determines whether the given `contentParent` object represents the root page (homepage).
   */
  const isRoot: boolean = contentParent === null

  if (isRoot) {
    makeTargetsAbsolute(acc)
    return acc
  }

  if (!isContentParent(contentParent)) {
    throw new Error(`contentParentToBreadcrumbs(): Provided [contentParent] object is not compliant with the [ContentParent] interface!\nProvided [contentParent] object: ${contentParent}`)
  }

  acc.unshift({
    label: contentParent.name,
    target: contentParent.slug
  })

  return contentParentToBreadcrumbs(contentParent.parent, acc)
}

/**
 * Returns the object describing the root `BootstrapBreadcrumbsItem`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export const getBreadcrumbsRoot = (): BootstrapBreadcrumbsItem => ({
  icon: BREADCRUMBS_ROOT_ICON_NAME,
  isExternal: true,
  label: '',
  target: process.env.VUE_APP_BNP_MAIN_PAGE ?? BREADCRUMBS_ROOT_FALLBACK_TARGET,
  wcagTitle: 'Strona Główna'
})

/**
 * Checks if the passed-in object correctly implements the `HeroModuleAnimationVersionContent` interface.
 *
 * @param subject - The subject (object) to check.
 *
 * @typeGuard
 *
 * @author Olha Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isHeroModuleAnimationVersionContent = (subject: any): subject is HeroModuleAnimationVersionContent => {
  return !(
    !isPlainObject(subject) ||
    typeof subject.addons === 'undefined' ||
    !Array.isArray(subject.addons) ||
    !isRelated(subject.gallery)
  )
}

/**
 * Checks if the passed-in object correctly implements the `HeroModuleBasicVersionContent` interface.
 *
 * @param subject - The subject (object) to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isHeroModuleBasicVersionContent = (subject: any): subject is HeroModuleBasicVersionContent => {
  return !(
    !isPlainObject(subject) ||
    typeof subject.addons === 'undefined' ||
    !Array.isArray(subject.addons) ||
    !isRelated(subject.backgroundImage)
  )
}

/**
 * Checks if the passed-in object correctly implements the `HeroModuleSliderVersionContent` interface.
 *
 * @param subject - The subject (object) to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isHeroModuleSliderVersionContent = (subject: any): subject is HeroModuleSliderVersionContent => {
  return !(
    !isPlainObject(subject) ||
    typeof subject.slides === 'undefined' ||
    !Array.isArray(subject.slides)
  )
}

/**
 * Makes the target property of every breadcrumbs item absolute.
 *
 * @param breadcrumbs - The breadcrumbs array to update.
 *
 * @mutates
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const makeTargetsAbsolute = (breadcrumbs: BootstrapBreadcrumbsItem[]): void => {
  const targets: string[] = []

  breadcrumbs.forEach(breadcrumb => {
    targets.push(breadcrumb.target as string)
    breadcrumb.target = targets.join('/')
  })

  if (breadcrumbs.length > 0 && breadcrumbs[0].target === '') {
    breadcrumbs[0].target = '/'
  }
}
