










import { Component, Prop, Vue } from 'vue-property-decorator'

import { log } from '../../../../../../support'

/**
 * SVG circle that can be filled with color in a given percentage.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<FillableCircle>({ name: 'FillableCircle' })
export class FillableCircle extends Vue {
  /**
   * Color of the circle.
   */
  @Prop({ type: String, required: true })
  public readonly color!: string

  /**
   * Percentage of the circle that should be filled with color.
   */
  @Prop({ type: Number, required: true })
  public readonly fillPercentage!: number

  /**
   * Size of the circle (in px) (border-box).
   */
  @Prop({ type: Number, required: true })
  public readonly size!: number

  /**
   * Circle thickness (as a ).
   */
  @Prop({ type: Number, required: true })
  public readonly thickness!: number

  /**
   * Value for the `stroke-dasharray` CSS property that determines which part of the circle should
   * be filled.
   */
  public get drawSize (): number {
    const circum = 2 * this.radius * Math.PI
    return this.fillPercentage * circum / 100
  }

  /**
   * The radius of the circle.
   */
  public get radius (): number {
    return 50 - this.thickness / 2
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasColor && this.hasSize && this.hasStrokeWidth
  }

  /**
   * Determines whether the component has been provided with the correct `color` prop.
   */
  private get hasColor (): boolean {
    if (typeof this.color !== 'string') {
      log(`FillableCircle.hasColor(): Expected [this.color] to be a type of [string], but got [${typeof this.color}]!`, 'error')
    }

    return typeof this.color === 'string'
  }

  /**
   * Determines whether the component has been provided with the correct `size` prop.
   */
  private get hasSize (): boolean {
    if (typeof this.size !== 'number') {
      log(`FillableCircle.hasSize(): Expected [this.size] to be a type of [number], but got [${typeof this.size}]!`, 'error')
    }

    return typeof this.size === 'number'
  }

  /**
   * Determines whether the component has been provided with the correct `strokeWidth` prop.
   */
  private get hasStrokeWidth (): boolean {
    if (typeof this.thickness !== 'number') {
      log(`FillableCircle.hasStrokeWidth(): Expected [this.strokeWidth] to be a type of [number], but got [${typeof this.thickness}]!`, 'error')
    }

    return typeof this.thickness === 'number'
  }
}

export default FillableCircle
