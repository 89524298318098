








































import { BootstrapButton, BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../../../../models'
import { isLink } from '../../../../../../../../support'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<BasicSection>({
  name: 'BasicSection',
  components: {
    BootstrapLink,
    BootstrapButton,
    SectionBase: () => import(
      /* webpackChunkName: "frame" */
      '../SectionBase/SectionBase.vue'
    )
  }
})
export class BasicSection extends Vue {
  /**
   * Determines whether the element should have top border.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasTopBorder!: boolean

  /**
   * Single navigation item with children.
   */
  @Prop({ type: Object, required: true })
  public readonly nav!: NavigationItem

  /**
   * State of options list
   */
  public isOptionListOpen: boolean = false

  /**
   * Check if main nav object is proper link
   */
  public get isProperLink (): boolean {
    return isLink(this.nav) && this.nav.target !== '/#'
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasLabel && this.hasLinks
  }

  /**
   * Toggles the state of option list
   */
  public toggleOptionListState (): void {
    this.isOptionListOpen = !this.isOptionListOpen
  }

  /**
   * Determines whether the component has been provided with the correct `label` prop.
   */
  private get hasLabel (): boolean {
    return typeof this.nav.label === 'string' && this.nav.label.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `links` prop.
   */
  private get hasLinks (): boolean {
    return typeof this.nav.children !== 'undefined' &&
      Array.isArray(this.nav.children) &&
      this.nav.children.length > 0
  }

  // Animation methods
  beforeEnter (el: HTMLElement): void {
    el.style.height = '0'
  }

  beforeLeave (el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  enter (el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  leave (el: HTMLElement): void {
    el.style.height = '0'
  }
}

export default BasicSection
