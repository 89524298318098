// Copyright © 2022 Move Closer

import { AsyncComponent } from 'vue'

import { MosaicModuleVersion } from '../Mosaic.contracts'

export const mosaicModuleVersionComponentRegistry: Record<MosaicModuleVersion, AsyncComponent> = {
  [MosaicModuleVersion.Basic]: () => import(
    /* webpackChunkName: "modules/Mosaic" */
    './versions/Basic/Basic.vue'
  )
}
