











import { Component, Prop, Vue } from 'vue-property-decorator'

import { log } from '../../../../../support'

import { DEFAULT_VARIANT } from './Heading.config'
import { HeadingAddon, HeadingVariant } from './Heading.contracts'

/**
 * UI component for the `HeroModuleAddonType.Heading`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<HeadingAddonUi>({
  name: 'HeadingAddonUi',
  components: {
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class HeadingAddonUi extends Vue {
  @Prop({ type: String, required: true })
  public readonly content!: HeadingAddon['content']

  @Prop({ type: String, required: false, default: DEFAULT_VARIANT })
  private readonly variant!: Required<HeadingAddon>['variant']

  @Prop({ type: String, required: false })
  private readonly font!: HeadingAddon['font']

  @Prop({ type: Boolean, required: false, default: false })
  public withGallery!: boolean

  /**
   * Registry that binds the heading's size variant with the applicable CSS class.
   */
  private readonly headingVariantClassNameRegistry: Record<HeadingVariant, string> = {
    [HeadingVariant.Normal]: '--normal',
    [HeadingVariant.Large]: '--large',
    [HeadingVariant.XLarge]: '--x-large'
  }

  /**
   * CSS class that determines the heading's size.
   */
  public get headingVariantClassName (): string {
    if (!(this.variant in this.headingVariantClassNameRegistry)) {
      log(`Given heading size variant [${this.variant}] is not supported. Default variant ([${DEFAULT_VARIANT}]) has been set`, 'warn')
      return this.headingVariantClassNameRegistry[DEFAULT_VARIANT]
    }

    return this.headingVariantClassNameRegistry[this.variant]
  }

  /**
   * Determines whether the component has been provided with the correct `content` @Prop.
   */
  public get hasContent (): boolean {
    return typeof this.content === 'string' && this.content.length > 0
  }
}

export default HeadingAddonUi
