



























import { AsyncComponent } from 'vue'
import { BootstrapImage, BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { log } from '../../../../../../support'

import {
  ContainerColumnGap,
  ContainerData,
  ContainerMargin,
  ContainerPadding,
  ContainerRowGap,
  ContainerWidth
} from '../../../../../atoms'

import { heroModuleAddonTypeUiComponentRegistry } from '../../../Hero.config'
import {
  HeroModuleAddonType,
  HeroModuleAnimationGridSetup,
  HeroModuleBaseContent,
  HeroModuleSlideContent,
  HeroModuleVersion
} from '../../../Hero.contracts'
/**
 * Component that's capable of rendering the contents of a single Swiper slide.
 * Can be used without the Swiper.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<SlideContent>({
  name: 'SlideContent',
  components: {
    BootstrapImage,
    AnimatedGrid: () => import(
      /* webpackChunkName: "molecules/AnimatedGrid" */
      '../AnimatedGrid/AnimatedGrid.vue'
    ),
    UiContainer: () => import(
      /* webpackChunkName: "atoms/UiContainer" */
      '../../../../../atoms/UiContainer/UiContainer.vue'
    )
  }
})
export class SlideContent extends Vue {
  /**
   * @see HeroModuleSlideContent.addons
   */
  @Prop({ type: Array, required: true })
  public readonly addons!: HeroModuleSlideContent['addons']

  /**
   * @see HeroModuleSlideContent.backgroundImage
   */
  @Prop({ type: Object, required: false, default: null })
  public readonly backgroundImage!: BootstrapImageProps | null

  /**
   * @see HeroModuleAnimationContent.gallery
   */
  @Prop({ type: Array, required: false })
  public readonly galleryImages?: Array<BootstrapImageProps>

  /**
   * @see HeroModuleAnimationContent.gallery
   */
  @Prop({ type: Object, required: false })
  public readonly gallerySetup?: HeroModuleAnimationGridSetup

  /**
   * @see HeroModuleBaseContent.containerWidth
   */
  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: HeroModuleBaseContent['containerWidth']

  /**
   * @see HeroModuleContent.hasBottomSpacing
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<HeroModuleBaseContent>['hasBottomSpacing']

  /**
   * @see HeroModuleBaseContent.showBreadcrumbs
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly showBreadcrumbs!: boolean

  /**
   * Version of the module. Determines which Vue component
   * will be used to render the departments.
   *
   * @see HeroModuleVersion
   */
  @Prop({ type: String, required: true })
  public readonly version!: HeroModuleVersion

  /**
   * Determines if slide is with gallery or not
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly withGallery!: boolean

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Value for the `data` @Prop of the `<UiContainer>`.
   */
  public get containerData (): ContainerData {
    return {
      id: this.uuid,
      gridGap: {
        column: ContainerColumnGap.Unset,
        row: ContainerRowGap.Unset
      },
      margin: {
        bottom: ContainerMargin.Unset,
        top: ContainerMargin.Unset
      },
      padding: {
        bottom: ContainerPadding.Unset,
        top: ContainerPadding.Unset
      },
      width: this.containerWidth
    }
  }

  /**
   * Resolves the applicable Vue component for the passed-in `HeroModuleAddonType`.
   */
  public getAddonComponent (addonType: HeroModuleAddonType): AsyncComponent | undefined {
    const component = heroModuleAddonTypeUiComponentRegistry[addonType]

    if (typeof component === 'undefined') {
      log(`HeroModuleUiPresentation.getAddonComponent(): There's no component binding for the passed-in [${addonType}] HeroModuleAddonType!`, 'warn')
      return
    }

    return component
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    if (!this.withGallery) {
      return this.hasBackgroundImage
    }
    return this.hasGalleryImages
  }

  /**
   * Determines whether the component has been provided with the correct `backgroundImage` prop.
   */
  private get hasBackgroundImage (): boolean {
    // noinspection JSIncompatibleTypesComparison
    return typeof this.backgroundImage === 'object' &&
      this.backgroundImage !== null &&
      typeof this.backgroundImage.src === 'string'
  }

  /**
   * Determines whether the component has been provided with the correct `galleryImages` prop.
   */
  private get hasGalleryImages (): boolean {
    return typeof this.galleryImages !== 'undefined' && Array.isArray(this.galleryImages) &&
      this.galleryImages.length !== 0
  }
}

export default SlideContent
