













import { BootstrapIcon, BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../../../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<SocialLinks>({
  name: 'SocialLinks',
  components: { BootstrapIcon, BootstrapLink }
})
export class SocialLinks extends Vue {
  /**
   * Array of social links to render.
   */
  @Prop({ type: Array, required: true })
  public readonly links!: NavigationItem[]

  /**
   * Determines whether the component has been provided with the correct `links` prop.
   */
  public get hasLinks (): boolean {
    return typeof this.links !== 'undefined' &&
      Array.isArray(this.links) &&
      this.links.length > 0
  }
}

export default SocialLinks
