












import { BootstrapBreadcrumbs, BootstrapBreadcrumbsItem } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<Breadcrumbs>({
  name: 'Breadcrumbs',
  components: { BootstrapBreadcrumbs }
})
export class Breadcrumbs extends Vue {
  /**
   * Array of breadcrumbs to render.
   */
  @Prop({ type: Array, required: true })
  public readonly breadcrumbs!: BootstrapBreadcrumbsItem[]

  /**
   * Determines whether the component has been provided with the correct `breadcrumbs` prop.
   *
   * @see breadcrumbs
   */
  public get hasItems (): boolean {
    return typeof this.breadcrumbs !== 'undefined' &&
      Array.isArray(this.breadcrumbs) &&
      this.breadcrumbs.length > 0
  }
}

export default Breadcrumbs
