import { render, staticRenderFns } from "./BasicSection.vue?vue&type=template&id=2ec3272a&scoped=true&"
import script from "./BasicSection.vue?vue&type=script&lang=ts&"
export * from "./BasicSection.vue?vue&type=script&lang=ts&"
import style0 from "./BasicSection.vue?vue&type=style&index=0&id=2ec3272a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec3272a",
  null
  
)

export default component.exports