





















































import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../../models'

import { ResolvedContactSection } from '../../../Footer.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<FooterMiddle>({
  name: 'FooterMiddle',
  components: {
    BasicSection: () => import(
      /* webpackChunkName: "frame" */
      './partials/BasicSection/BasicSection.vue'
    ),
    BottomLinks: () => import(
      /* webpackChunkName: "frame" */
      './partials/BottomLinks/BottomLinks.vue'
    ),
    ContactSection: () => import(
      /* webpackChunkName: "frame" */
      './partials/ContactSection/ContactSection.vue'
    ),
    GoToTopBtn: () => import(
      /* webpackChunkName: "frame" */
      '../FooterTop/partials/GoToTopBtn/GoToTopBtn.vue'
    ),
    SocialLinks: () => import(
      /* webpackChunkName: "frame" */
      './partials/SocialLinks/SocialLinks.vue'
    ),
    TopLinks: () => import(
      /* webpackChunkName: "frame" */
      './partials/TopLinks/TopLinks.vue'
    )
  }
})
export class FooterMiddle extends Vue {
  /**
   * Array of navigation links to render on the **bottom** of the middle section.
   */
  @Prop({ type: Array, required: false })
  public readonly bottomNav?: NavigationItem[]

  /**
   * Content for the "contact" section.
   */
  @Prop({ type: Object, required: false })
  public readonly contactSection?: ResolvedContactSection

  /**
   * Array of main navigation links.
   */
  @Prop({ type: Array, required: false })
  public readonly mainNav?: NavigationItem[]

  /**
   * Array of navigation item with social links to render.
   */
  @Prop({ type: Array, required: false })
  public readonly socialNav?: NavigationItem[]

  /**
   * NOTICE!: These links are appeared only in mobile devices.
   * Array of links to render.
   */
  @Prop({ type: Array, required: false })
  public readonly topNav?: NavigationItem[]

  /**
   * Determines whether the component has been provided with the correct `bottomLinks` prop.
   */
  public get hasBottomLinks (): boolean {
    return typeof this.bottomNav !== 'undefined' &&
      Array.isArray(this.bottomNav) &&
      this.bottomNav.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `contactSection` prop.
   */
  public get hasContactSection (): boolean {
    return typeof this.contactSection !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `sections` prop.
   */
  public get hasMainNav (): boolean {
    return typeof this.mainNav !== 'undefined' &&
      Array.isArray(this.mainNav) &&
      this.mainNav.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `socialLinks` prop.
   */
  public get hasSocialNav (): boolean {
    return typeof this.socialNav !== 'undefined' &&
      Array.isArray(this.socialNav) &&
      this.socialNav.length > 0
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasBottomLinks || this.hasContactSection || this.hasMainNav || this.hasSocialNav
  }
}

export default FooterMiddle
