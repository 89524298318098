// Copyright © 2022 Move Closer

import { createMachine, Typestate } from 'xstate'

/**
 * Context that can be shared between the states.
 * In this case it's an alias for an empty object as this machine does not have any context.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type MachineContext = Record<string, never>

/**
 * Available events that can toggle the transitions between the component's states.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum MachineEvent {
  /**
   * When the User scrolls the page DOWN.
   */
  ScrollDown = 'scrollDown',

  /**
   * When the scroll reaches the page's top.
   */
  ScrollTop = 'scrollTop',

  /**
   * When the User scrolls the page UP.
   */
  ScrollUp = 'scrollUp',

  /**
   * When the User clicks the toggler button.
   */
  TogglerClick = 'togglerClick',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface MachineEventObject {
  /**
   * The type of the event being fired.
   */
  type: MachineEvent
}

/**
 * Possible states that the component can be found in.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum MachineState {
  /**
   * Brand image, toggler button and bottom links are visible. Top links are hidden.
   */
  BrandImageAndBottomLinks = 'brandImageAndBottomLinks',

  /**
   * Brand image and toggler button are visible. Top and bottom links are hidden.
   */
  BrandImageOnly = 'brandImageOnly',

  /**
   * All contents are hidden. Every collapsible section is collapsed.
   */
  Hidden = 'hidden',

  /**
   * All contents are visible. Every collapsible section is expanded.
   * That's the initial state of the component.
   */
  Visible = 'visible',
}

/**
 * Finite state machine capable of controlling the state of the `<NavbarDesktop>` partial.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const navbarDesktopMachine = createMachine<
  MachineContext,
  MachineEventObject,
  Typestate<MachineContext> & { value: MachineState }
>({
  id: 'navbarDesktopMachine',
  initial: MachineState.Visible,
  states: {
    [MachineState.BrandImageOnly]: {
      on: { [MachineEvent.TogglerClick]: MachineState.BrandImageAndBottomLinks }
    },
    [MachineState.BrandImageAndBottomLinks]: {
      on: { [MachineEvent.TogglerClick]: MachineState.BrandImageOnly }
    },
    [MachineState.Hidden]: {
      on: { [MachineEvent.ScrollUp]: MachineState.BrandImageOnly }
    },
    [MachineState.Visible]: {}
  },
  on: {
    [MachineEvent.ScrollDown]: MachineState.Hidden,
    [MachineEvent.ScrollTop]: MachineState.Visible
  }
})
