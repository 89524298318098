














import { AsyncComponent } from 'vue'
import { BootstrapBreadcrumbsItem, BootstrapImage, BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '../../../../support'

import { ContainerWidth } from '../../../atoms'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { heroModuleVersionComponentRegistry } from './Hero.ui.config'
import {
  HeroModuleAnimationGridSetup,
  HeroModuleBaseContent,
  HeroModuleVersion,
  ResolvedHeroModuleAddon,
  ResolvedHeroModuleSlideContent
} from '../Hero.contracts'

/**
 * Presentational component for the `HeroModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
@Component<HeroModuleUiPresentation>({
  name: 'HeroModuleUiPresentation',
  components: {
    BootstrapImage,
    Breadcrumbs: () => import(
      /* webpackChunkName: "frame" */
      './partials/Breadcrumbs/Breadcrumbs.vue'
    ),
    UiContainer: () => import(
      /* webpackChunkName: "atoms/UiContainer" */
      '../../../atoms/UiContainer/UiContainer.vue'
    )
  }
})
export class HeroModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see HeroModuleBasicVersionContent.addons
   */
  @Prop({ type: Array, required: false })
  public readonly addons?: ResolvedHeroModuleAddon[]

  /**
   * @see HeroModuleBasicVersionContent.backgroundImage
   */
  @Prop({ type: Object, required: false })
  public readonly backgroundImage?: BootstrapImageProps

  /**
   * Array of breadcrumbs to render.
   */
  @Prop({ type: Array, required: false })
  public readonly breadcrumbs?: BootstrapBreadcrumbsItem[]

  /**
   * @see HeroModuleBaseContent.containerWidth
   */
  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: HeroModuleBaseContent['containerWidth']

  /**
   * @see HeroModuleAnimationContent.gallery
   */
  @Prop({ type: Array, required: false })
  public readonly galleryImages?: Array<BootstrapImageProps>

  /**
   * Setup for animated grid
   */
  @Prop({ type: Object, required: false })
  public readonly gallerySetup?: HeroModuleAnimationGridSetup

  /**
   * @see HeroModuleContent.hasBottomSpacing
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<HeroModuleBaseContent>['hasBottomSpacing']

  /**
   * @see HeroModuleBaseContent.showBreadcrumbs
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly showBreadcrumbs!: boolean

  /**
   * @see HeroModuleSliderVersionContent.slides
   */
  @Prop({ type: Array, required: false })
  public readonly slides!: ResolvedHeroModuleSlideContent[]

  /**
   * Version of the module. Determines which Vue component
   * will be used to render the departments.
   *
   * @see HeroModuleVersion
   */
  @Prop({ type: String, required: true })
  private readonly version!: HeroModuleVersion

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Vue component that should be used to render the departments.
   */
  public get component (): AsyncComponent | undefined {
    const component = heroModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(`HeroModuleUiPresentation.component(): There's no Vue component associated with the [${this.version}] HeroModuleVersion!`, 'error')
      return
    }

    return component
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return typeof this.component === 'function'
  }

  /**
   * Determines whether the component has been provided with the correct `breadcrumbs` @Prop.
   */
  public get hasBreadcrumbs (): boolean {
    return typeof this.breadcrumbs === 'object' &&
      Array.isArray(this.breadcrumbs) &&
      this.breadcrumbs.length > 0
  }
}

export default HeroModuleUiPresentation
