















import { BootstrapImage, BootstrapImageProps, BootstrapLink, Link } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Brand>({
  name: 'Brand',
  components: { BootstrapImage, BootstrapLink }
})
export class Brand extends Vue {
  /**
   * Brand's image (its logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly image?: BootstrapImageProps

  /**
   * Link associated with the brand's image (logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly link?: Link

  /**
   * Text to be rendered next to the brand's image (logotype).
   */
  @Prop({ type: String, required: false })
  public readonly text?: string

  /**
   * Determines whether the component has been provided with the correct `image` prop.
   */
  public get hasImage (): boolean {
    return typeof this.image !== 'undefined' && typeof this.image.src === 'string'
  }

  /**
   * Determines whether the component has been provided with the correct `link` prop.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' && typeof this.link.target !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `text` prop.
   */
  public get hasText (): boolean {
    return typeof this.text === 'string' && this.text.length > 0
  }

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasImage || this.hasText || (this.hasLink && typeof this.link?.label !== 'undefined')
  }
}

export default Brand
