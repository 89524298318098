// Copyright © 2022 Move Closer

/**
 * Finds first element in given container which can be focusable.
 *
 * @param container - Any HTMLElement, usually semantic blocks (ex: header, section, main, etc..) or just <div>
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const findFirstFocusableElement = (container: HTMLElement): Element | undefined => {
  const focusableEl = Array.from(container.getElementsByTagName('*')).find((element) => isFocusable(element as HTMLElement))

  if (typeof focusableEl === 'undefined') {
    const main = document.getElementsByTagName('main')[0]

    if (typeof main === 'undefined') {
      return undefined
    }

    return main
  }

  return focusableEl
}

/**
 * Checks whether element can be focused.
 *
 * @param element - HtmlElement
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const isFocusable = (element: HTMLElement): boolean => {
  if (element.tabIndex < 0) {
    return false
  }

  if (element.classList.contains('d-none') || element.classList.contains('d-lg-none') || element.classList.contains('d-sm-none') || element.classList.contains('d-md-none')) {
    return false
  }

  switch (element.tagName) {
    case 'A':
      return !!(element as HTMLLinkElement).href
    case 'INPUT':
      return (element as HTMLInputElement).type !== 'hidden' && !(element as HTMLInputElement).disabled
    case 'SELECT':
    case 'TEXTAREA':
    case 'BUTTON':
      return !(element as HTMLInputElement).disabled
    default:
      return false
  }
}

/**
 * Searches for parent element and returns it if exists.
 *
 * @param element - Current element.
 * @param level - Level of parent to reach.
 *
 * @returns HTMLElement - Found parent.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const searchParentElement = (element: HTMLElement, level: number = 1): HTMLElement | undefined => {
  const foundElement = element.parentElement

  if (!foundElement) {
    return
  }

  if (level === 1) {
    return foundElement
  }

  return searchParentElement(foundElement, level - 1)
}
