





















import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<FooterTop>({
  name: 'FooterTop',
  components: {
    GoToTopBtn: () => import(
      /* webpackChunkName: "frame" */
      './partials/GoToTopBtn/GoToTopBtn.vue'
    ),
    Links: () => import(
      /* webpackChunkName: "frame" */
      './partials/Links/Links.vue'
    )
  }
})
export class FooterTop extends Vue {
  /**
   * Array of navigation links to render.
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public readonly nav!: NavigationItem[]

  /**
   * Determines whether the component has been provided with the correct `nav` prop.
   */
  public get hasNav (): boolean {
    return typeof this.nav !== 'undefined' &&
      Array.isArray(this.nav) &&
      this.nav.length > 0
  }
}

export default FooterTop
