











import { BootstrapButton, BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'

import { isLink } from '../../../../support'
import { LinkWithLabel } from '../../../../contracts'

import { AbstractModuleUiPresentation } from '../../_abstract'

/**
 * Presentational component for the `FloatingButtonModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<FloatingButtonModuleUiPresentation>({
  name: 'FloatingButtonModuleUiPresentation',
  components: { BootstrapButton, BootstrapLink }
})
export class FloatingButtonModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Link associated with the button.
   */
  @Prop({ type: Object, required: true })
  public readonly link!: LinkWithLabel

  /**
   * Determines whether there's a valid link to render.
   */
  public get hasLink (): boolean {
    return isLink(this.link)
  }
}

export default FloatingButtonModuleUiPresentation
