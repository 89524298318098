










import { AsyncComponent } from 'vue'
// import { BootstrapImage } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '../../../../support'

import { ContainerWidth } from '../../../atoms'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { mosaicModuleVersionComponentRegistry } from './Mosaic.ui.config'
import {
  // MosaicModuleAnimationGridSetup,
  MosaicModuleBaseContent,
  MosaicModuleVersion,
  ResolvedMosaicModuleAddon
  // ResolvedMosaicModuleSlideContent
} from '../Mosaic.contracts'
import { ResolvesRelatedAsync } from '../../../../services'

@Component<MosaicModuleUiPresentation>({
  name: 'MosaicModuleUiPresentation',
  components: {
    // BootstrapImage,
    // UiContainer: () => import(
    //   /* webpackChunkName: "atoms/UiContainer" */
    //   '../../../atoms/UiContainer/UiContainer.vue'
    // )
  }
})
export class MosaicModuleUiPresentation extends AbstractModuleUiPresentation {
  @Prop({ type: Array, required: false })
  public readonly addons?: ResolvedMosaicModuleAddon[]

  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: MosaicModuleBaseContent['containerWidth']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<MosaicModuleBaseContent>['hasBottomSpacing']

  @Prop({ type: String, required: true })
  private readonly version!: MosaicModuleVersion

  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  public get component (): AsyncComponent | undefined {
    const component = mosaicModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(`MosaicModuleUiPresentation.component(): There's no Vue component associated with the [${this.version}] MosaicModuleVersion!`, 'error')
      return
    }

    return component
  }

  public get shouldRender (): boolean {
    return typeof this.component === 'function'
  }
}

export default MosaicModuleUiPresentation
