











import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { UseNonce } from '../../../../../../extensions'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<UiGrid>({
  name: 'UiGrid'
})
export class UiGrid extends Mixins<UseNonce>(UseNonce) {
  /**
   * Given column gap given as string with proper css value
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap
   */
  @Prop({ type: String, required: false })
  public columnGap?: string

  /**
   * Given numbers of columns
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-collumns
   */
  @Prop({ type: [Number, String], required: false, default: 6 })
  public columns!: number | string

  /**
   * Items to render in grid
   */
  @Prop({ type: Array, required: false, default: () => { return [] } })
  public items!: Array<unknown>

  /**
   * Given row gap given as string with proper css value
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/row-gap
   */
  @Prop({ type: String, required: false })
  public rowGap?: number

  /**
   * Given numbers of rows
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows
   */
  @Prop({ type: [Number, String], required: false, default: 4 })
  public rows!: number | string

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Computed grid styles
   */
  public get style (): string {
    const styles: AnyObject = {}

    if (typeof this.rows === 'number') {
      styles.gridTemplateRows = `repeat(${(this.rows)}, minmax(0, 1fr))`
    } else {
      styles.gridTemplateRows = this.rows
    }

    if (typeof this.columns === 'number') {
      styles.gridTemplateColumns = `repeat(${(this.columns)}, minmax(0, 1fr))`
    } else {
      styles.gridTemplateColumns = this.columns
    }

    styles.rowGap = this.rowGap
    styles.columnGap = this.columnGap

    return `
      #Grid-${this.htmlId} {
        grid-template-rows: ${styles.gridTemplateRows};
        grid-template-columns: ${styles.gridTemplateColumns};
        ${styles.rowGap ? `row-gap: ${styles.rowGap};` : ''}
        ${styles.columnGap ? `column-gap: ${styles.columnGap};` : ''}
      }
    `
  }
}

export default UiGrid
