






import { Component, Prop, Vue } from 'vue-property-decorator'

import { TextAddon } from './Text.contracts'

/**
 * UI component for the `HeroModuleAddonType.Text`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TextAddonUi>({
  name: 'TextAddonUi',
  components: {
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class TextAddonUi extends Vue {
  /**
   * @see TextAddon.content
   */
  @Prop({ type: String, required: true })
  public readonly content!: TextAddon['content']

  /**
   * Determines whether the component has been provided with the correct `content` @Prop.
   */
  public get hasContent (): boolean {
    return typeof this.content === 'string' && this.content.length > 0
  }
}

export default TextAddonUi
