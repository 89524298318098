










import { BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../../../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Links>({
  name: 'Links',
  components: { BootstrapLink }
})
export class Links extends Vue {
  /**
   * Array of links to render.
   */
  @Prop({ type: Array, required: true })
  public readonly links!: NavigationItem[]

  /**
   * Determines whether the component has been provided with the correct `links` prop.
   */
  public get hasLinks (): boolean {
    return typeof this.links !== 'undefined' &&
      Array.isArray(this.links) &&
      this.links.length > 0
  }
}

export default Links
