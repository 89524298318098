



















import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Helper component for the `<BasicSection>` and `<ContactSection>` components.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SectionBase>({
  name: 'SectionBase',
  components: {
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../../../atoms/UiHeading/UiHeading.vue'
    )
  }
})
export class SectionBase extends Vue {
  /**
   * Determines whether the element should have top border.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasTopBorder!: boolean
}

export default SectionBase
