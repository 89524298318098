











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BootstrapImageProps } from '@movecloser/ui-core'

import { NavigationItem } from '../../../../../../models'
import { ResolvedLink } from '../../../../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<FooterBottom>({
  name: 'FooterBottom',
  components: {
    Brand: () => import(
      /* webpackChunkName: "frame" */
      './partials/Brand/Brand.vue'
    ),
    Creator: () => import(
      /* webpackChunkName: "frame" */
      './partials/Creator/Creator.vue'
    ),
    Links: () => import(
      /* webpackChunkName: "frame" */
      './partials/Links/Links.vue'
    )
  }
})
export class FooterBottom extends Vue {
  /**
   * Small disclaimer text.
   */
  @Prop({ type: String, required: false })
  public readonly disclaimer?: string

  /**
   * Brand image (its logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandImage?: BootstrapImageProps

  /**
   * Link associated with the brand's image (logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandLink?: ResolvedLink

  /**
   * Text to be rendered next to the brand's image (logotype).
   */
  @Prop({ type: String, required: false })
  public readonly motto?: string

  /**
   * Copyright text.
   */
  @Prop({ type: String, required: false })
  public readonly copyright?: string

  /**
   * Array of links to render.
   */
  @Prop({ type: Array, required: false })
  public readonly nav?: NavigationItem[]

  /**
   * Determines whether the component has been provided with the correct `disclaimer` prop.
   */
  public get hasDisclaimer (): boolean {
    return typeof this.disclaimer === 'string' && this.disclaimer.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `brand...` props.
   */
  public get hasBrand (): boolean {
    // In case there's **no brand image nor brand text**,
    // we have to explicitly check for the existence of the link's label.
    // If it DOES exist, we can proceed with rendering, but when the link's label isn NOT defined,
    // there's literally nothing to render to the end User, so the getter should return `false`.
    if (!this.hasBrandImage && !this.hasBrandText) {
      const hasLinkLabel: boolean = typeof this.brandLink?.label !== 'undefined'
      return this.hasBrandLink && hasLinkLabel
    }

    return this.hasBrandImage || this.hasBrandLink || this.hasBrandText
  }

  /**
   * Determines whether the component has been provided with the correct `copyright` prop.
   */
  public get hasCopyright (): boolean {
    return typeof this.copyright === 'string' && this.copyright.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `links` prop.
   */
  public get hasNavigation (): boolean {
    return typeof this.nav !== 'undefined' &&
      Array.isArray(this.nav) &&
      this.nav.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `brandImage` prop.
   */
  private get hasBrandImage (): boolean {
    return typeof this.brandImage !== 'undefined' &&
      typeof this.brandImage.src === 'string' &&
      this.brandImage.src.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `brandLink` prop.
   */
  private get hasBrandLink (): boolean {
    return typeof this.brandLink !== 'undefined' && typeof this.brandLink.target !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `brandText` prop.
   */
  private get hasBrandText (): boolean {
    return typeof this.motto === 'string' && this.motto.length > 0
  }
}

export default FooterBottom
