




















































































import { BootstrapIcon, BootstrapLink, Link } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../../../../models'

import { FooterPhones } from '../../../../../Footer.contracts'

/**
 * TODO:
 *   - Add missing `RingingPhone` icon.
 *   - Add missing icons in the "button" section.
 *   - Improve RWD on tablets.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ContactSection>({
  name: 'ContactSection',
  components: {
    BootstrapIcon,
    BootstrapLink,
    SectionBase: () => import(
      /* webpackChunkName: "frame" */
      '../SectionBase/SectionBase.vue'
    ),
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class ContactSection extends Vue {
  /**
   * Section's heading. Needed also for the accessibility concerns.
   */
  @Prop({ type: String, required: true })
  public readonly heading!: string

  /**
   * Array of buttons to render.
   */
  @Prop({ type: Array, required: false })
  public readonly iconNav?: NavigationItem[]

  /**
   * Content for the "phones" subsection.
   */
  @Prop({ type: Object, required: false })
  public readonly phones?: FooterPhones

  /**
   * Constructs the `Link` object for the passed-in phone number.
   *
   * @param phone - The phone number of interest.
   */
  public getPhoneLink (phone: string): Link {
    if (typeof phone !== 'string' || phone.length === 0) {
      return { target: '#' }
    }

    return {
      target: `tel:${phone.split(' ').join('')}`,
      isExternal: true,
      newTab: false
    }
  }

  /**
   * Determines whether the component has been provided with the correct `buttons` prop.
   */
  public get hasIcoNav (): boolean {
    return typeof this.iconNav !== 'undefined' &&
      Array.isArray(this.iconNav) &&
      this.iconNav.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `phones` prop.
   */
  public get hasPhones (): boolean {
    return typeof this.phones !== 'undefined'
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasHeading && (this.hasIcoNav || this.hasPhones)
  }

  /**
   * Determines whether the component has been provided with the correct `heading` prop.
   */
  private get hasHeading (): boolean {
    return typeof this.heading === 'string' && this.heading.length > 0
  }
}

export default ContactSection
