










import { Component, Prop } from 'vue-property-decorator'
import { DebouncedFunc, throttle } from 'lodash'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { ResolvedFooterModuleContent } from '../Footer.contracts'

/**
 * Presentational component for the `FooterModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
@Component<FooterModuleUiPresentation>({
  name: 'FooterModuleUiPresentation',
  components: {
    FooterBottom: () => import(
      /* webpackChunkName: "frame" */
      './partials/FooterBottom/FooterBottom.vue'
    ),
    FooterMiddle: () => import(
      /* webpackChunkName: "frame" */
      './partials/FooterMiddle/FooterMiddle.vue'
    ),
    FooterTop: () => import(
      /* webpackChunkName: "frame" */
      './partials/FooterTop/FooterTop.vue'
    )
  },
  mounted (): void {
    FooterModuleUiPresentation.setComponentCSSSettings()
  },
  beforeDestroy (): void {
    FooterModuleUiPresentation.clearComponentCSSSettings()
  }
})
export class FooterModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Content for the **bottom** section of the component.
   */
  @Prop({ type: Object, required: false })
  public readonly bottom?: ResolvedFooterModuleContent['bottom']

  /**
   * Content for the **middle** section of the component.
   */
  @Prop({ type: Object, required: false })
  public readonly middle?: ResolvedFooterModuleContent['middle']

  /**
   * Content for the **top** section of the component.
   */
  @Prop({ type: Object, required: false })
  public readonly top?: ResolvedFooterModuleContent['top']

  /**
   * Updates the CSS variables managed by this component.
   *
   * @throttled
   */
  public static updateCSSVariables: DebouncedFunc<() => void> =
    throttle(FooterModuleUiPresentation.setCSSVariables, 100)

  /**
   * Determines whether the component has been provided with the correct `bottom` prop.
   */
  public get hasBottom (): boolean {
    return typeof this.bottom !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `middle` prop.
   */
  public get hasMiddle (): boolean {
    return typeof this.middle !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `top` prop.
   */
  public get hasTop (): boolean {
    return typeof this.top !== 'undefined'
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasMiddle || this.hasTop
  }

  /**
   * Clears the CSS variables managed by this component.
   */
  private static clearComponentCSSSettings (): void {
    if (typeof window === 'undefined') {
      return
    }

    FooterModuleUiPresentation.unsetCSSVariables()

    window.removeEventListener('resize', FooterModuleUiPresentation.updateCSSVariables)
  }

  /**
   * Sets-up the required CSS variables and updates them every time the `window` size has changed.
   */
  private static setComponentCSSSettings (): void {
    if (typeof window === 'undefined') {
      return
    }

    FooterModuleUiPresentation.setCSSVariables()

    window.addEventListener('resize', FooterModuleUiPresentation.updateCSSVariables)
  }

  /**
   * Sets-up the needed CSS variables.
   */
  private static setCSSVariables (): void {
    const root: HTMLElement = document.documentElement
    const bodyWidth: number = document.body.getBoundingClientRect().width
    const vw: number = bodyWidth * 0.01
    root.style.setProperty('--vw', vw + 'px')
  }

  /**
   * Un-sets the previously set-up CSS variables.
   */
  private static unsetCSSVariables (): void {
    const root: HTMLElement = document.documentElement
    root.style.removeProperty('--vw')
  }
}

export default FooterModuleUiPresentation
