// Copyright © 2022 Move Closer

import { AsyncComponent } from 'vue'

import { HeroModuleVersion } from '../Hero.contracts'

// noinspection JSValidateJSDoc
/**
 * Fallback value for the `target` property of the root `BootstrapBreadcrumbsItem` object.
 *
 * @see BootstrapBreadcrumbsItem.target
 * @see process.env.VUE_APP_BNP_MAIN_PAGE
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const BREADCRUMBS_ROOT_FALLBACK_TARGET: string = 'https://www.bnpparibas.pl/'

/**
 * Value for the `icon` property of the root `BootstrapBreadcrumbsItem` object.
 *
 * @see BootstrapBreadcrumbsItem.icon
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const BREADCRUMBS_ROOT_ICON_NAME: string = 'Home'

/**
 * Registry that binds the `HeroModuleVersion` with the applicable Vue component.
 *
 * @see HeroModule
 * @see HeroModuleVersion
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const heroModuleVersionComponentRegistry: Record<HeroModuleVersion, AsyncComponent> = {
  [HeroModuleVersion.Basic]: () => import(
    /* webpackChunkName: "modules/Hero" */
    './versions/Basic/Basic.vue'
  ),
  [HeroModuleVersion.Slider]: () => import(
    /* webpackChunkName: "modules/Hero" */
    './versions/Slider/Slider.vue'
  ),
  [HeroModuleVersion.Animation]: () => import(
    /* webpackChunkName: "modules/Hero" */
    './versions/Animated/Animated.vue'
  )
}
