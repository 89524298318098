

















import { BootstrapButton, BootstrapLink, BootstrapTheme, Link } from '@movecloser/ui-core'
import { Component, Inject, Mixins, Prop } from 'vue-property-decorator'

import { Alignmentable } from '../../../../../extensions'
import { HorizontalAlignment } from '../../../../../contracts'

import { LinkAddon } from './Link.contracts'

/**
 * UI component for the `HeroModuleAddonType.Link`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<LinkAddonUi>({
  name: 'LinkAddonUi',
  components: { BootstrapButton, BootstrapLink }
})
export class LinkAddonUi extends Mixins<Alignmentable>(Alignmentable) {
  /**
   * @see LinkAddon.content
   */
  @Prop({ type: Object, required: true })
  public readonly link!: LinkAddon['link']

  /**
   * @see LinkAddon.uppercase
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly uppercase!: LinkAddon['uppercase']

  /**
   * Determines whether the app is running on a mobile phone.
   */
  @Inject({ from: 'isPhone', default: false })
  private readonly isPhone!: boolean

  public readonly BootstrapTheme = BootstrapTheme

  /**
   * Registry that binds the `HorizontalAlignment` with the applicable CSS class.
   *
   * @override
   */
  public readonly alignmentClassNameRegistry = {
    base: {
      [HorizontalAlignment.Center]: 'justify-content-center',
      [HorizontalAlignment.Justify]: 'justify-content-stretch',
      [HorizontalAlignment.Left]: 'justify-content-start',
      [HorizontalAlignment.Right]: 'justify-content-end'
    },
    desktop: {
      [HorizontalAlignment.Center]: 'justify-content-lg-center',
      [HorizontalAlignment.Justify]: 'justify-content-lg-stretch',
      [HorizontalAlignment.Left]: 'justify-content-lg-start',
      [HorizontalAlignment.Right]: 'justify-content-lg-end'
    }
  }

  /**
   * Determines whether the component has been provided with the correct `link` @Prop.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' &&
      (Object.prototype.hasOwnProperty.call(this.link, 'target') &&
        typeof (this.link as Link).target !== 'undefined')
  }
}

export default LinkAddonUi
