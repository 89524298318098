







import { Component } from 'vue-property-decorator'

import { isLink, log } from '../../../../support'
import { isUnresolvedLink, LinkWithLabel } from '../../../../contracts'

import { AbstractModuleUi } from '../../_abstract'

import { FloatingButtonModule } from '../FloatingButton.contracts'

/**
 * Container component for the `FloatingButtonModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<FloatingButtonModuleUi>({
  name: 'FloatingButtonModuleUi',
  components: {
    FloatingButtonModuleUiPresentation: () => import(
      /* webpackChunkName: "frame" */
      './FloatingButton.ui.presentation.vue'
    ),
    ModuleUnavailablePlaceholder: () => import(
      /* webpackChunkName: "cms/frame" */
      '../../../molecules/ModuleUnavailablePlaceholder/ModuleUnavailablePlaceholder.vue'
    )
  }
})
export class FloatingButtonModuleUi extends AbstractModuleUi<FloatingButtonModule> {
  /**
   * Button's link from `this.data.content` with all related data already resolved and ready to use.
   */
  public link: LinkWithLabel | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this._fetchLink()
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasLink
  }

  /**
   * Fetches the actual link from the `RelatedService`.
   */
  private async _fetchLink (): Promise<void> {
    const unknownLink = this.data.content.link

    if (isLink(unknownLink)) {
      this.link = unknownLink
      return
    }

    if (isUnresolvedLink(unknownLink)) {
      try {
        this.link = await this.fetchLink(unknownLink)
      } catch (error) {
        const message: string = 'FloatingButtonModuleUi._fetchLink(): Failed to fetch the related link from the [RelatedService]!'
        log([message, error], 'error')
      }
    }
  }

  /**
   * Determines whether the `link` property has an appropriate data inside.
   *
   * @see link
   * @see _fetchLink
   */
  private get hasLink (): boolean {
    return isLink(this.link)
  }
}

export default FloatingButtonModuleUi
