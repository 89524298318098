







import { Component } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { AbstractModuleUi } from '../../_abstract'

import { SkiplinksModule } from '../Skiplinks.contracts'

/**
 * Container component for the `SkiplinksModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<SkiplinksModuleUi>({
  name: 'SkiplinksModuleUi',
  components: {
    SkiplinksModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Skiplinks" */
      './Skiplinks.ui.presentation.vue'
    )
  }
})
export class SkiplinksModuleUi extends AbstractModuleUi<SkiplinksModule> {
  /**
   * An instance of the `EventBus` service.
   */
  public get eventBus (): IEventbus {
    return this.resolveInjection<IEventbus>(EventbusType)
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent
  }
}

export default SkiplinksModuleUi
