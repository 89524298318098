








import { BootstrapBreadcrumbsItem, BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Inject, Provide } from 'vue-property-decorator'
import { isPlainObject } from 'lodash'

import { ArrayElement, log, toBootstrapImageProps } from '../../../../support'
import { DescriptionOfImageFile, ImageRatio, isImageFile } from '../../../../models'
import { DriverConfig, isRelated, RelatedRecord } from '../../../../services'
import {
  isContentParent,
  isUnresolvedLink,
  LinkWithLabel,
  ModuleImageRatio,
  REGISTRY_RESPONSE_INJECTION_KEY,
  RegistryResponse,
  Related, RelatedOption,
  RelatedType
} from '../../../../contracts'

import { DEFAULT_GRID_CONFIG } from './partials/UiGrid'

import { AbstractModuleUi } from '../../_abstract'

import {
  HeroModule,
  HeroModuleAddon,
  HeroModuleAddonType,
  HeroModuleAnimationGridSetup,
  HeroModuleVersion,
  ResolvedHeroModuleAddon,
  ResolvedHeroModuleSlideContent
} from '../Hero.contracts'
import { LinkAddon, ResolvedLinkAddon, ResolvedSearchAddon, SearchAddon } from '../addons'

import {
  contentParentToBreadcrumbs,
  getBreadcrumbsRoot,
  isHeroModuleAnimationVersionContent,
  isHeroModuleBasicVersionContent,
  isHeroModuleSliderVersionContent
} from './Hero.ui.helpers'

/**
 * Container component for the `HeroModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<HeroModuleUi>({
  name: 'HeroModuleUi',
  components: {
    HeroModuleUiPresentation: () => import(
      /* webpackChunkName: "frame" */
      './Hero.ui.presentation.vue'
    )
  }
})
export class HeroModuleUi extends AbstractModuleUi<HeroModule> {
  /**
   * `RegistryResponse` object fetched from the API.
   */
  @Inject({ from: REGISTRY_RESPONSE_INJECTION_KEY, default: null })
  private readonly registryResponse!: RegistryResponse | null

  /**
   * @see HeroModuleBasicVersionContent.addons
   */
  public addons: HeroModuleAddon[] = []

  /**
   * @see HeroModuleBasicVersionContent.backgroundImage
   */
  public backgroundImage: BootstrapImageProps | null = null

  /**
   * @see HeroModuleAnimationContent.gallery
   */
  public galleryImages: Array<BootstrapImageProps> = []

  /**
   * Secondary list of images.
   */
  public secondaryImages: Array<BootstrapImageProps> = []

  /**
   * @see HeroModuleSliderVersionContent.slides
   */
  public slides: ResolvedHeroModuleSlideContent[] = []

  /**
   * @inheritDoc
   */
  protected versionEnum = HeroModuleVersion

  /**
   * @inheritDoc
   */
  protected imageRatios: ModuleImageRatio = {
    desktop: ImageRatio.HeroDesktop,
    mobile: ImageRatio.HeroMobile
  }

  /**
   * @see HeroModuleUiPresentation.breadcrumbs
   */
  public get breadcrumbs (): BootstrapBreadcrumbsItem[] {
    if (this.registryResponse === null) {
      log('HeroModuleUi.breadcrumbs(): [this.registryResponse] is [null]!', 'error')
      return []
    }

    const contentParent = this.registryResponse.content.parent

    if (!this.isRoot && !isContentParent(contentParent)) {
      log('HeroModuleUi.breadcrumbs(): [this.registryResponse.content.parent] is not compliant with the [ContentParent] interface!', 'error')
      return []
    }

    const breadcrumbs: BootstrapBreadcrumbsItem[] = contentParentToBreadcrumbs(contentParent)

    const currentPageBreadcrumb: BootstrapBreadcrumbsItem = {
      label: this.registryResponse.content.title ?? '',
      target: '#'
    }
    breadcrumbs.push(currentPageBreadcrumb)

    // TL;DR: Do NOT swap the `homepageBreadcrumb` with the `currentPageBreadcrumb`.
    //
    // NOTE: The actions' order is very important here, because **the `breadcrumbs` array sometimes
    //  can be empty**. Particularly, it will happen when the current page is a homepage, which is
    //  the root of the pages tree. In that case the below assignment to the `label` property would
    //  result in an error. However, it will NOT happen if we create and push (`Array.push()`) the
    //  breadcrumb item for the current page first. In this situation the `breadcrumbs` array will
    //  have exactly one item, which represents - at the same time - the current page and
    //  the homepage.
    const homepageBreadcrumb: BootstrapBreadcrumbsItem = breadcrumbs[0]
    homepageBreadcrumb.label = this.$t('_.careers') as string

    const breadcrumbsRoot: BootstrapBreadcrumbsItem = getBreadcrumbsRoot()
    breadcrumbs.unshift(breadcrumbsRoot)

    return breadcrumbs
  }

  /**
   * Get number of images which should be render in animated gallery
   */
  public get galleryCapacity (): number {
    if (!isHeroModuleAnimationVersionContent(this.data.content)) {
      return 0
    }

    const rows: number = this.data?.content?.rows || DEFAULT_GRID_CONFIG.rows
    const columns: number = this.data?.content?.columns || DEFAULT_GRID_CONFIG.columns

    return rows * columns
  }

  /**
   * Get gallery setUp form content
   */
  public get gallerySetup (): HeroModuleAnimationGridSetup | undefined {
    if (!isHeroModuleAnimationVersionContent(this.data.content)) {
      return
    }
    return {
      rows: this.data?.content?.rows,
      columns: this.data?.content?.columns,
      animationDuration: this.data?.content?.animationDuration
    }
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await Promise.allSettled([
      this.fetchAddons(),
      this.fetchBackgroundImage(),
      this.fetchGallery(),
      this.fetchSlides()
    ])
  }

  /**
   * Fetches single gallery image from the `RelatedService`.
   */
  @Provide()
  public fetchGalleryImage (): BootstrapImageProps {
    if (this.secondaryImages.length === 0) {
      throw new Error('AbstractModuleUi.fetchGalleryImage(): There is no images to resolve')
    }

    const image = this.secondaryImages.pop()

    if (image === undefined) {
      throw new Error('AbstractModuleUi.fetchGalleryImage(): There is no images to resolve')
    }

    return image
  }

  @Provide()
  public updateSecondary (image: BootstrapImageProps): void {
    this.secondaryImages.push(image)
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    if (!this.hasContent || !this.hasValidVersion) {
      return false
    }

    switch (this.data.version) {
      case HeroModuleVersion.Basic: {
        return this.hasBackgroundImage
      }
      case HeroModuleVersion.Slider: {
        return this.hasSlides
      }
      case HeroModuleVersion.Animation: {
        return this.hasGalleryImages
      }
      default: {
        return false
      }
    }
  }

  /**
   * Resolves the related data inside every addon.
   *
   * @see addons
   */
  private async fetchAddons (): Promise<void> {
    if (isHeroModuleSliderVersionContent(this.data.content.addons)) {
      return
    }

    this.addons = await this.resolveAddons(this.data.content.addons)
  }

  /**
   * Fetches the background image from the `RelatedService`.
   */
  private async fetchBackgroundImage (): Promise<void> {
    if (!isHeroModuleBasicVersionContent(this.data.content)) {
      return
    }

    const unresolvedImage = this.data?.content?.backgroundImage

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      this.backgroundImage = await this.fetchImage(unresolvedImage)
    } catch (error) {
      const message: string =
        'HeroModuleUi.fetchBackgroundImage(): Failed to fetch the background image from the [Related Service]!'
      log([message, error], 'error')
    }
  }

  /**
   * Fetches the gallery images from the `RelatedService`.
   */
  private async fetchGallery (): Promise<void> {
    if (!isHeroModuleAnimationVersionContent(this.data.content)) {
      return
    }

    const unresolvedGallery = this.data?.content?.gallery

    if (!isRelated(unresolvedGallery)) {
      return
    }

    try {
      const gallery = await this.fetchGalleryImages(unresolvedGallery, {})

      this.galleryImages = gallery.slice(0, this.galleryCapacity)
      this.secondaryImages = gallery
    } catch (error) {
      const message: string =
        'HeroModuleUi.fetchGallery(): Failed to fetch the gallery images from the [Related Service]!'
      log([message, error], 'error')
    }
  }

  /**
   * Resolves the related data inside every slide.
   *
   * @see slides
   */
  private async fetchSlides (): Promise<void> {
    if (!isHeroModuleSliderVersionContent(this.data.content)) {
      return
    }

    const possibleSlides: PromiseSettledResult<ResolvedHeroModuleSlideContent>[] =
      await Promise.allSettled(this.data.content.slides.map(slide => {
        return new Promise<ResolvedHeroModuleSlideContent>((resolve, reject) => {
          this.resolveAddons(slide.addons)
            .then(resolvedAddons => {
              if (!isRelated(slide.backgroundImage)) {
                return reject(new Error('HeroModuleUi.fetchSlides(): Slide\'s background image is NOT a valid [Related] object!'))
              }

              this.fetchImage(slide.backgroundImage)
                .then(resolvedBackgroundImage => {
                  return resolve({
                    addons: resolvedAddons,
                    backgroundImage: resolvedBackgroundImage
                  })
                })
                .catch(error => {
                  const message: string = 'HeroModuleUi.fetchSlides(): Failed to resolve the background image for the following slide:'
                  log([message, slide, error], 'error')
                  return reject(error)
                })
            })
            .catch(error => {
              const message: string = 'HeroModuleUi.fetchSlides(): Failed to resolve the addons for the following slide:'
              log([message, slide, error], 'error')
              return reject(error)
            })
        })
      }))

    const resolvedSlides: ResolvedHeroModuleSlideContent[] = []

    possibleSlides.forEach(slide => {
      if (slide.status === 'fulfilled') {
        resolvedSlides.push(slide.value)
      }
    })

    this.slides = resolvedSlides
  }

  /**
   * Determines whether the background image has been successfully fetched from the `RelatedService`.
   */
  private get hasBackgroundImage (): boolean {
    return this.backgroundImage !== null
  }

  /**
   * Determines whether the gallery images has been successfully fetched from the `RelatedService`.
   */
  private get hasGalleryImages (): boolean {
    return Array.isArray(this.galleryImages) && this.galleryImages.length === this.galleryCapacity
  }

  /**
   * Determines whether the slides' content has been successfully fetched from the `RelatedService`.
   */
  private get hasSlides (): boolean {
    return typeof this.slides !== 'undefined' &&
      Array.isArray(this.slides) &&
      this.slides.length > 0
  }

  /**
   * Determines whether the current page is the root page (homepage).
   */
  private get isRoot (): boolean {
    return this.registryResponse?.content.parent === null
  }

  /**
   * Fetches the specified unresolved gallery from the `RelatedService`.
   *
   * @param unresolvedGallery - The gallery to resolve.
   * @param config - config for relatedService resolve method
   *
   * @returns - Array of objects compliant with the `BootstrapImageProps` interface,
   * ready to use inside the presentational components.
   *
   * @throws Error - if the provided `unresolvedGallery` argument is not compliant with the `Related` interface.
   * @throws Error - if the resolved data is not compliant with the `ImageFile` interface.
   */
  private async fetchGalleryImages (unresolvedGallery: Related<RelatedType.Gallery>, config: DriverConfig):
    Promise<Array<BootstrapImageProps>> {
    if (!isRelated(unresolvedGallery)) {
      throw new Error(`AbstractModuleUi.fetchGalleryImages(): Provided [unresolvedGallery] argument is NOT compliant with the [Related] interface!\n[unresolvedGallery] value: ${JSON.parse(JSON.stringify(unresolvedGallery))}.`)
    }

    const imagesFile: DescriptionOfImageFile[] =
      await this.relatedService.resolve<DescriptionOfImageFile[]>(unresolvedGallery, config)

    imagesFile.forEach(image => {
      if (!isImageFile(image)) {
        throw new Error('AbstractModuleUi.fetchGalleryImages(): Resolved data is NOT compliant with the [ImageFile] interface!')
      }
    })

    return imagesFile.map<BootstrapImageProps>(image => {
      return toBootstrapImageProps(image, ImageRatio.Square)
    })
  }

  /**
   * Resolves the related data for the passed-in array of addons.
   *
   * @param unresolvedAddons - The array of addons to resolve.
   */
  private async resolveAddons (unresolvedAddons: HeroModuleAddon[]): Promise<ResolvedHeroModuleAddon[]> {
    const possibleAddons: PromiseSettledResult<ResolvedHeroModuleAddon>[] =
      await Promise.allSettled(unresolvedAddons.map(addon => {
        return new Promise<ResolvedHeroModuleAddon>((resolve, reject) => {
          switch (addon.type) {
            case HeroModuleAddonType.Link: {
              this.resolveLinkAddon(addon as LinkAddon)
                .then(resolvedAddon => { resolve(resolvedAddon) })
                .catch(error => {
                  const message: string = 'HeroModuleUi.resolveAddons(): Failed to resolve the related data for the following addon:'
                  log([message, addon, error], 'error')
                  reject(error)
                })
              break
            }

            case HeroModuleAddonType.Search: {
              this.resolveSearchAddon(addon as SearchAddon)
                .then(resolvedAddon => { resolve(resolvedAddon) })
                .catch(error => {
                  const message: string = 'HeroModuleUi.resolveAddons(): Failed to resolve the related data for the following addon:'
                  log([message, addon, error], 'error')
                  reject(error)
                })
              break
            }

            default: {
              resolve(addon as ResolvedHeroModuleAddon)
            }
          }
        })
      }))

    const resolvedAddons: ResolvedHeroModuleAddon[] = []

    possibleAddons.forEach(addon => {
      if (addon.status === 'fulfilled') {
        resolvedAddons.push(addon.value)
      }
    })

    return resolvedAddons
  }

  /**
   * Resolves all related data for the passed-in `LinkAddon`.
   *
   * @param unresolvedAddon - `LinkAddon` which data is to be resolved.
   */
  private async resolveLinkAddon (unresolvedAddon: LinkAddon): Promise<ResolvedLinkAddon> {
    if (!isUnresolvedLink(unresolvedAddon.link)) {
      return unresolvedAddon as ResolvedLinkAddon
    }

    const resolvedLink: LinkWithLabel = await this.fetchLink(unresolvedAddon.link)

    return {
      ...unresolvedAddon,
      link: resolvedLink
    }
  }

  /**
   * Resolves all related data for the passed-in `SearchAddon`.
   *
   * @param unresolvedAddon - `SearchAddon` which data is to be resolved.
   */
  private async resolveSearchAddon (unresolvedAddon: SearchAddon): Promise<ResolvedSearchAddon> {
    const relatedRecord: RelatedRecord = this.relatedService.getRecord()

    if (!isPlainObject(relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): Expected [relatedRecord] to be a plain object, but instead got [${typeof relatedRecord}]!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (!(RelatedType.DepartmentsOptions in relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [relatedRecord] is missing the [departments] key!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (!(RelatedType.RegionsOptions in relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [relatedRecord] is missing the [regions] key!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (!(RelatedType.JobsModel in relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [relatedRecord] is missing the [jobsModel] key!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (unresolvedAddon.searchResultsPage === null) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [unresolvedAddon.searchResultsPage] is [null]!\n[unresolvedAddon] object: ${unresolvedAddon}`)
    }

    const departments: ResolvedSearchAddon['departments'] =
      (relatedRecord[RelatedType.DepartmentsOptions] as unknown as RelatedOption[])
        .map(({ id, name, option }) => ({
          label: option ?? name,
          value: id
        }))

    const locations: ResolvedSearchAddon['locations'] =
      (relatedRecord[RelatedType.RegionsOptions] as unknown as RelatedOption[])
        .map(({ id, name }) => ({
          label: name,
          value: id.toString()
        }))

    const jobsModels: ResolvedSearchAddon['jobsModels'] =
      Object.values((relatedRecord[RelatedType.JobsModel] as unknown as Record<string, string>))
        .map((option) => {
          return {
            label: this.$t(`_.jobModel.${option}`).toString(),
            value: option
          }
        })

    let searchResultsPage: ResolvedSearchAddon['searchResultsPage']

    if (isUnresolvedLink(unresolvedAddon.searchResultsPage)) {
      searchResultsPage = await this.fetchLink(unresolvedAddon.searchResultsPage)
    } else {
      searchResultsPage = unresolvedAddon.searchResultsPage
    }

    return { ...unresolvedAddon, departments, locations, jobsModels, searchResultsPage }
  }
}

export default HeroModuleUi
