// Copyright © 2023 Move Closer

import { OptionsListColumnsDefinition } from './OptionsList.contracts'

export const optionsListColumnsDefaultConfig: OptionsListColumnsDefinition = {
  responsive: {
    desktop: {
      columnsCount: 2,
      maxColumnCapacity: 15
    },
    mobile: {
      columnsCount: 1,
      maxColumnCapacity: 1000
    }
  }
}

export const departmentsListColumnsConfig: OptionsListColumnsDefinition = {
  responsive: {
    desktop: {
      columnsCount: 2,
      maxColumnCapacity: 15
    },
    mobile: {
      columnsCount: 1,
      maxColumnCapacity: 1000
    }
  }
}

export const locationsListColumnsConfig: OptionsListColumnsDefinition = {
  responsive: {
    desktop: {
      columnsCount: 2,
      maxColumnCapacity: 9
    },
    mobile: {
      columnsCount: 1,
      maxColumnCapacity: 1000
    }
  }
}

export const jobModelsListColumnsConfig: OptionsListColumnsDefinition = {
  responsive: {
    desktop: {
      columnsCount: 1,
      maxColumnCapacity: 1000
    },
    mobile: {
      columnsCount: 1,
      maxColumnCapacity: 1000
    }
  }
}
